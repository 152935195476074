@import "imports.scss";


/* ------------------------------------------------  */
/* UNIVERSAL */
/* ------------------------------------------------  */

::selection {
  background: #031d44; /* WebKit/Blink Browsers */
  color:#FFF;
}

::-moz-selection {
  background: #031d44; /* Gecko Browsers */
  color:#FFF;
}

html, body {
  margin: 0;
  padding: 0;
  border: 0;
  overflow-x:hidden;
}

html {
  height: 100%;
}

body {
  color: $color-type-dark;
  background: #FFF;
  font-family: $font-family-montserrat;
  margin-top:58px;
  overflow:hidden;
}

img {
  width:100%;
}

.container {
  max-width:1280px; /* 1060 */

  &.workspace {
    padding-top:20px;
  }
}

.block {
  padding:90px 0 90px 0;
}

.right {
  text-align:right;
}

.left {
  text-align:left;
}


h1 {
  font-size:40px;
  font-family: $font-family-raleway;
  font-weight:900;
  color:$color-type-dark;
  line-height:1.2em;

  &.hashtag {
    background-color: $color-hsorange;
    font-family:$font-family-raleway;
    color: $color-type-dark;
    padding:10px 20px;
    border-radius:6px;
    width:max-content;
    margin-top:6px;
  }
}

h2 {
  font-size:22px;
  font-family: $font-family-raleway;
  font-weight:900;
  color:$color-type-dark;
  line-height:1.2em;
  padding-bottom:20px;
  text-transform: uppercase;
}

h3 {
  font-size:24px;
  font-family: $font-family-raleway;
  font-weight: 400;
  color: $color-type-dark;
  line-height: 1.2em;
  padding:0 0 10px 0;
}

h4 {
  font-size:22px;
  font-family: $font-family-montserrat;
  font-weight: 600;
  color: #240D4D;
  line-height: 1.2em;
}


h5 {
  font-size:20px;
  font-family: $font-family-montserrat;
  font-weight: 600;
  color: #3A99FE;
  line-height: 1.2em;
}

a {
  color:#008CFF;
  text-decoration:none;

  &:hover {
    color:darken(#008CFF, 10%);
    text-decoration:underline;
  }
}


p, ol, ul {
  font-family: $font-family-montserrat;
  font-weight:400;
  line-height:1.5em;
  color:#606060;   /* #39364F */
  font-size:18px;
}


b {
  font-family: $font-family-montserrat;
  font-weight:600;
}


.align-self-center {
  align-self: center !important;
}

a.anchor {
  margin-bottom:60px;
  display:block;
}


/* BUTTONS */
/* -------------------*/

.btn {
  padding:10px 20px;
  text-transform:uppercase;
  border-radius:12px;
  text-decoration:none;
  width:auto;
  margin-left:10px;

  &.small {
    width:100%;
    margin-left:0;
    padding:6px 14px;
    font-size:14px;
  }

  &.main {
    color:$color-type-dark;
    background:$color-hsorange;
    border:2px solid $color-hsorange;
    font-family:$font-family-raleway;
    font-weight:800;
    font-size:16px;

    &:hover {
      background-color: darken($color-hsorange, 10%);
      border-color: darken($color-hsorange, 10%);
      text-decoration:none;
    }
  }

  &.outline {
    color:$color-type-dark;
    background:transparent;
    border:2px solid #3D3D3D;
    font-family:$font-family-raleway;
    font-weight:800;
    font-size:16px;

    &:hover {
      background-color: darken(#3D3D3D, 10%);
      border-color: darken(#3D3D3D, 10%);
      text-decoration:none;
      color:#FFF;
    }
  }

  &.solid {
    color:$color-white;
    background:#3D3D3D;
    border:2px solid #3D3D3D;
    font-family:$font-family-raleway;
    font-weight:800;
    font-size:16px;

    &:hover {
      background-color: darken(#3D3D3D, 10%);
      border-color: darken(#3D3D3D, 10%);
      text-decoration:none;
      color:#FFF;
    }
  }
}

/* NAVBAR */
/* -------------------*/

.navbar.fixed-top>.container {
  max-width: 1280px;
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      padding-right:.8rem;
      padding-left:.8rem;
      color:#FFFFFF;
      font-family:$font-family-raleway;
      font-weight:500;
      font-size:16px;

      &:hover {
        color:#9D9D9D;
      }

      &:focus {
        color: #FFFFFF;
      }

      &.active {
        color:#FFFFFF;
      }
    }

    &.active {
      color:#FF0000 !important;
    }
  }

  .navbar-toggler {
    border-color:#FFF;
  }
}

.nav-item {
  font-size:14px;
}

.navbar {

  background-color:#000000;

  .navbar-brand {
    height:auto;

    img {
      width:140px;
    }
  }
}

.navbar.fixed-top {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
}

.buffer {
  height:100px;
}

footer {
  bottom: 0;
  width: 100%;
  line-height: 75px;
  background-color: $color-bg-light;

  .icons {
    text-align:right;

    a {
      padding-left:12px;

      img {
        width:36px;
      }
    }
  }

  .links {
    a {
      font-size:14px;
      font-family:$font-family-raleway;
      display:inline-block;
      padding-right:16px;
      color:$color-type-dark;

      img {
        width:140px;
        margin-right:30px;
      }
    }
  }
}

/* HEADERS */
/* -------------------*/

.hdr-toptier {
  background-color:$color-bg-light;

  img.post {
    border-radius:12px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    margin-top:-40px;
  }

  .stablefor {
    width:150px;
    margin-bottom:10px;
  }

  .stats {
    margin-top:30px;

    .stat-block {
      border-left:1px solid #D6D6D6;
      padding:0 0 0 10px;
      display:inline-block;
      margin-right:20px;
      width:auto;

      &.first {
        border-left:none;
        padding:0 0 0 0;
      }

      p.label {
        font-size:16px;
        color:$color-type-medium;
        font-family:$font-family-raleway;
        font-weight:400;
        margin-bottom:8px;
      }

      p.stat {
        font-size:24px;
        color:$color-type-dark;
        font-family:$font-family-montserrat;
        font-weight:700;
        line-height:28px;
        margin-bottom:0;
      }
    }
  }
}

.hdr-midtier {
  background-color:$color-bg-lighter;
}

.maincontent {
  background-color:#FFFFFF;
  padding-top:50px;
}

.tracked {
  background-color:$color-bg-lighter;
  padding-top:50px;
}

.available {
  background-color:#FFF;
  padding-top:50px;
}

.dba {
  padding:6px 0 0 0;

  span.replies {
    font-family:$font-family-raleway;
    font-size:17px;
    color:$color-type-medium;
    padding-right:16px;
  }
  span.logged {
    font-family:$font-family-raleway;
    font-size:17px;
    color:$color-type-medium;
  }
}

p.engagement {
  font-family:$font-family-raleway;
  font-size:17px;
  color:$color-type-medium;
  margin:8px 0 0 0;
  font-style: italic;
}


.hdr-block {
  padding:50px 0 40px 0;
}

.hdr-action {
  text-align:right;
}

.body-block {
  padding:50px 0;
  background-color:#FFF;
}


/* WIDGETS */
/* -------------------*/

.filter-box {
  background-color: $color-bg-light;
  border-radius:12px;
  padding:16px;
  margin-bottom:40px;

  .row {
    line-height:40px;
    padding:8px 0;

    &.form-group {
      margin-bottom:0;
      border-bottom:1px solid #D6D6D6;
    }
  }

  i {
    display:inline-block;
    font-size:16px;
    margin-right:6px;
    line-height:16px;
  }

  span {
    display:inline-block;
    line-height:16px;
    font-size:16px;
  }

}

.post-box {
  padding:0 30px 10px 0;
  display: inline-block;

  img {
    border-radius:6px;
    max-width:200px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    margin-bottom:10px;

    &:hover {
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    }
  }
  
  a {
    text-decoration:none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .hashtag {
    background-color: $color-hsorange;
    font-family:$font-family-raleway;
    color: $color-type-dark;
    padding:10px 20px;
    border-radius:6px;
    font-size:20px;
    font-weight:900;
    width: auto; /* max-content */
    margin-bottom:10px;
  }

  .audit {
    white-space: nowrap;
    margin-bottom:30px;

    p.timestamp {
      font-family: $font-family-montserrat;
      font-size: 14px;
      color: $color-type-dark;
      margin-bottom:2px;
    }

    p.lasteditor {
      font-family: $font-family-montserrat;
      font-size: 14px;
      color: $color-type-dark;
    }
  }
}

.comment-box {
  border:0px solid #E6E6E6;
  padding:24px 20px 16px 20px;
  border-radius:12px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
  margin:0 0 20px 0;

  p.postauthor {
    font-family: $font-family-montserrat;
    font-weight:700;
    font-size:16px;
    margin-bottom:4px;
    line-height:16px;
    color:$color-type-dark;
  }

  p.commentbody {
    font-family: $font-family-montserrat;
    font-weight:400;
    font-size:16px;
    color:$color-type-medium;
  }

  .event {
    text-align:right;
  }

  p.date {
    font-family: $font-family-montserrat;
    font-weight:700;
    font-size:16px;
    margin-bottom:4px;
    line-height:16px;
  }

  p.time {
    font-family: $font-family-montserrat;
    font-weight:400;
    font-size:16px;
  }

  button.icons {
    display: inline-block;
    padding-right:8px;
    border:none;
    background-color:transparent;
    color:$color-type-light;
    font-family:$font-family-raleway;
    font-weight:600;
    font-size:18px;
    line-height:18px;

    &.reply {
      color:#008CFF;
    }

    &:hover {
      color: darken(#41C91B, 10%);
      text-decoration:none;
    }
  }

  .form-group {

    &.replies {
      padding-bottom:20px;

      textarea.reply {
        border-radius:12px;
        margin:8px 0 16px 0;
      }

      label.reply {
        font-weight:600;
        font-size:14px;
        margin-bottom:0;
      }

      .btn-small {
        padding:8px 16px;
        text-transform:uppercase;
        border-radius:8px;
        text-decoration:none;
        width:auto;
        margin:0 10px 0 0;
        font-family:$font-family-raleway;
        font-weight:800;
        font-size:14px;
        display:inline-block;

        &.reply {
          background:$color-hsorange;
          border:2px solid $color-hsorange;
          color:$color-type-dark;

          &:hover {
            background-color: darken($color-hsorange, 10%);
            border-color: darken($color-hsorange, 10%);
            text-decoration:none;
          }
        }

        &.outline {
          background:$color-white;
          border:2px solid #D6D6D6;
          color:$color-type-dark;

          &:hover {
            background-color: darken(#D6D6D6, 10%);
            border-color: darken($color-hsorange, 10%);
            text-decoration:none;
          }
        }
      }
    }
  }



  .actionbox {
    width:40px;
    display:inline-block;
    margin-right:4px;


    i {
      font-size:19px;
    }

    label {
      margin-bottom:0;
    }
  }

  .sentiment {
    padding-right:0;
    padding-left:0;
  }

  img.mentioned {
    border-radius:6px;
  }

  .full-comment {
    padding-left:0px;
  }

  .fa-circle {
    line-height:16px;
    margin-left:0px;
    float:none;
    display:block;
    text-align:center;


    &.sentiment-a {
      color:#FF0000;
    }

    &.sentiment-b {
      color:#00FF00;
    }

    &.sentiment-c {
      color:#0000FF;
    }
  }
}


/* TOGGLE */
/* -------------------*/

  input {
    display: none;
  }

  .toggleWrapper {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    padding:4px;
    line-height:18px;
    border-radius: 6px;
    background-color: #FFFFFF;
    text-align: center;
    color:#C6C6C6;

    &:active {
      padding:0;

      .toggle {
        padding:4px;
      }
    }

    .toggle {
      padding:4px;
      background-color: transparent;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  input:checked {
    & ~ .background {
      background-color: #f9faf7;
    }

    & + .toggleWrapper {
      background-color: #FFF;
      color:#3D3D3D;

      &:active {
      }

      .toggle {
        padding:4px;
      }
    }
  }

@import "mobile.scss";













