
@media only screen and (max-width:1024px) {


  h1 {
    font-size:30px;

    &.hashtag {
      font-size:24px;
      margin-top:6px;
    }
  }

  h2 {
    font-size:22px;
  }

  h3 {
    font-size:18px;
  }

  h4 {
    font-size:22px;
    font-family: $font-family-montserrat;
    font-weight: 600;
    color: #240D4D;
    line-height: 1.2em;
  }


  h5 {
    font-size:20px;
    font-family: $font-family-montserrat;
    font-weight: 600;
    color: #3A99FE;
    line-height: 1.2em;
  }

  .hdr-block {
    padding:26px 0 40px 0;
  }

  .hdr-action {
    text-align:left;
    padding:0;
    margin-top:20px;
  }

  .post-box {
    padding:0 0px 10px 0;

    img {
      border-radius:6px;
      max-width:200px;
      -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
      margin-bottom:10px;
      margin-right:10px;
    }

    .hashtag {
      margin-right:10px;
    }
  }

  .hdr-toptier {

    .stablefor {
      margin-bottom: 10px;
      margin-top:10px;
    }

    img.post {
      margin:-40px 0 20px 0;
    }


    .stats {

      .stat-block {
        border:0;
        padding:10px;
        display:inline-block;
        margin:4px;
        background-color:#E5E3E1;
        border-radius:6px;
        width:auto;


        &.first {
          padding:10px;
        }

        p.label {
          font-size:15px;
          margin-bottom:0px;
        }

        p.stat {
          font-size:20px;
          margin-bottom:0;
        }
      }
    }
  }


  .comment-box {
    margin:20px 0;

    p.postauthor {
      margin-bottom:10px;
    }

    .event {
      text-align:left;
      margin-top:20px;
    }

    p.date {
      display:inline-block;
      margin-right:10px;
      margin-bottom:0;
    }

    p.time {
      display:inline-block;
      margin-bottom:0;
    }


    .actionbox {
      width:40px;
      display:inline-block;
      margin-right:4px;

      label {
        margin-bottom:0;
      }
    }

    .full-comment {
      padding-left:15px;
    }

    img.mentioned {
      margin:16px 0;
    }

    .sentiment {
      padding-left:10px;
      padding-right:10px;
    }

    .fa-circle {
      line-height:16px;
      margin-left:0px;
      float:right;
    }
  }


  footer {
    line-height: 2em;
    padding:20px 15px;

    .icons {
      text-align:right;

      a {
        padding-left:12px;

        img {
          width:36px;
        }
      }
    }

    .links {
      a {
        font-size:14px;
        font-family:$font-family-raleway;
        display: block;
        padding-right:16px;
        color:$color-type-dark;

        img {
          width:140px;
          margin-right:30px;
          margin-bottom:10px;
        }
      }
    }
  }
}


@media only screen and (max-width:480px) {


  h1 {
    font-size:30px;

    &.hashtag {
      font-size:24px;
      margin-top:6px;
    }
  }

  h2 {
    font-size:22px;
  }

  h3 {
    font-size:18px;
  }

  h4 {
    font-size:22px;
    font-family: $font-family-montserrat;
    font-weight: 600;
    color: #240D4D;
    line-height: 1.2em;
  }


  h5 {
    font-size:20px;
    font-family: $font-family-montserrat;
    font-weight: 600;
    color: #3A99FE;
    line-height: 1.2em;
  }

  .hdr-block {
    padding:26px 0 40px 0;
  }

  .hdr-action {
    text-align:left;
    padding:0;
    margin-top:20px;
  }

  .post-box {
    padding:0 0px 10px 0;

    img {
      border-radius:6px;
      max-width:100%;
      -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
      margin-bottom:10px;
    }
  }

  .hdr-toptier {

    .stablefor {
      margin-bottom: 10px;
      margin-top:10px;
    }

    img.post {
      margin:0 0 20px 0;
    }


    .stats {

      .stat-block {
        border:0;
        padding:10px;
        display:inline-block;
        margin:4px;
        background-color:#E5E3E1;
        border-radius:6px;
        width:44%;


        &.first {
          padding:10px;
        }

        p.label {
          font-size:15px;
          margin-bottom:0px;
        }

        p.stat {
          font-size:20px;
          margin-bottom:0;
        }
      }
    }
  }


  .comment-box {
    margin:20px 0;

    p.postauthor {
      margin-bottom:10px;
    }

    .event {
      text-align:left;
      margin-top:20px;
    }

    p.date {
      display:inline-block;
      margin-right:10px;
      margin-bottom:0;
    }

    p.time {
      display:inline-block;
      margin-bottom:0;
    }


    .actionbox {
      width:40px;
      display:inline-block;
      margin-right:4px;

      label {
        margin-bottom:0;
      }
    }

    .full-comment {
      padding-left:15px;
    }

    img.mentioned {
      margin:16px 0;
    }

    .sentiment {
      padding-left:10px;
      padding-right:10px;
    }

    .fa-circle {
      line-height:16px;
      margin-left:0px;
      float:right;
    }
  }


  footer {
    line-height: 2em;
    padding:20px 15px;

    .icons {
      text-align:right;

      a {
        padding-left:12px;

        img {
          width:36px;
        }
      }
    }

    .links {
      a {
        font-size:14px;
        font-family:$font-family-raleway;
        display: block;
        padding-right:16px;
        color:$color-type-dark;

        img {
          width:140px;
          margin-right:30px;
          margin-bottom:10px;
        }
      }
    }
  }
}


























