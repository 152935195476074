/* Device = Most of the Smartphones Mobiles (Portrait) */
$screen-xxs-min: 320px;
$screen-xxs-max: 480px;

/* Device = Low Resolution Tablets, Mobiles (Landscape) */
$screen-xs-min: 481px;
$screen-xs-max: 767px;

/* Device = Tablets, Ipads (portrait) */
$screen-sm-min: 768px;
$screen-sm-max: 1024px;

/* Device = Laptops, Desktops */
$screen-md-min: 1025px;
$screen-md-max: 1280px;

/* Device = Desktops */
$screen-lg-min: 1281px;
$screen-lg-max: 1440px;

/* Higher Resolution Screens */
$screen-xlg-min: 1441px;
$screen-xlg-max: 2560px;

$font-weight-thin:          100;
$font-weight-exlight:       200;
$font-weight-light:         300;
$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-semibold:      600;
$font-weight-bold:          700;
$font-weight-exbold:        800;
$font-weight-black:         900;

$color-type-dark: #1D1D1D;
$color-type-medium: #3D3D3D;
$color-type-light: #D6D6D6;
$color-hsblack: #000000;
$color-hsorange: #FBB03B;
$color-white: #FFFFFF;
$color-bg-light: #F6F4ED;
$color-bg-lighter: #FAF9F6;

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;