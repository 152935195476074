//Transition Mixin//

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

//Rotate Mixin//

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

//Box-shadow Mixin//
@mixin box-shadow($args) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}

//Calc Mixin//
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -ms-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

//flex-direction Mixin//
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

//flex order Mixin//
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

//Animation Mixin//

@mixin keyframe($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }

  @-moz-keyframes $animation-name {
    @content;
  }

  @-o-keyframes $animation-name {
    @content;
  }

  @keyframes $animation-name {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// Contextual backgrounds

@mixin bg-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus() {
      background-color: darken($color, 10%) !important;
    }
  }
  @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

@mixin bg-gradient-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background: $color
      linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  }
  @include deprecate(
    "The `bg-gradient-variant` mixin",
    "v4.5.0",
    "v5",
    $ignore-warning
  );
}
